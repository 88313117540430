@if (!!data && control) {
  @if (data.length > 2 || isPaginated || isSingleSelectionDisabled) {
    <mat-form-field
      [ngClass]="{ 'no-arrow ': isLoading }"
      class="full-width"
      [hideRequiredMarker]="hideRequiredMarker"
    >
      <mat-label>
        {{ label }}
        @if (
          ((!!data && data.length > 2) || isPaginated) && !isMultiChoiceDisabled
        ) {
          <span>
            ({{ control.value?.length || 0 }}/{{ totalCount || data?.length }})
          </span>
        }
      </mat-label>

      <mat-select
        [panelClass]="'ds-filter-panel'"
        (openedChange)="focusOnChange()"
        [class.mat-mdc-select-disabled]="isLoading"
        [multiple]="!isMultiChoiceDisabled"
        [formControl]="control"
        [errorStateMatcher]="errorStateMatcher"
        [attr.data-cy]="label + '-select'"
      >
        @if (isPaginated) {
          <mat-select-trigger>
            @if (isArray(control.value)) {
              @for (value of control.value; track $index; let first = $first) {
                @if (dataLookup['' + value]) {
                  @if (!first) {
                    <span>, </span>
                  }
                  <span [textContent]="dataLookup['' + value].viewValue"></span>
                }
              }
              @if (selectedButNotLoadedValues.length) {
                @if (
                  selectedButNotLoadedValues.length === control.value.length
                ) {
                  <span>
                    {{
                      'filter_input.selected_label'
                        | translate
                          : { count: selectedButNotLoadedValues.length }
                    }}
                  </span>
                } @else {
                  ,
                  {{
                    'filter_input.more_label'
                      | translate: { count: selectedButNotLoadedValues.length }
                  }}
                }
              }
            } @else {
              @if (control.value) {
                @if (dataLookup['' + control.value]) {
                  <span
                    [textContent]="dataLookup['' + control.value].viewValue"
                  ></span>
                } @else {
                  <span class="singe-value">{{ control.value }}</span>
                }
              }
            }
          </mat-select-trigger>
        }
        <div class="header">
          <div class="label">
            @if (
              !isSelectAllDisabled &&
              !!data &&
              data.length > 2 &&
              !isPaginated &&
              !isMultiChoiceDisabled
            ) {
              <mat-checkbox
                #checkBox
                [indeterminate]="
                  !!control.value &&
                  !!data &&
                  control.value.length &&
                  control.value.length < data.length
                "
                [checked]="control.value?.length === data?.length"
                (change)="toggleAllSelection()"
              >
                <strong>
                  {{ label }}

                  @if ((!!data && data.length > 2) || isPaginated) {
                    <span>
                      ({{ control.value?.length || 0 }}/{{
                        totalCount || data?.length
                      }})
                    </span>
                  }
                </strong>
              </mat-checkbox>
            } @else {
              <strong>
                {{ label }}
                @if (!!data && data.length > 2 && !isMultiChoiceDisabled) {
                  <span
                    >({{ control.value?.length || 0 }}/{{ data?.length }})</span
                  >
                }
              </strong>
            }
          </div>
          <div fxLayout="row">
            @if ((!!data && data.length > 6) || isPaginated) {
              <input
                #inputSelect
                [id]="label + '_search'"
                class="mat-mdc-input-element mat-mdc-form-field-input-control custom-input"
                type="text"
                [placeholder]="'customer.btn_search' | translate"
                [formControl]="searchControl"
                data-cy="filter-search"
                (keydown)="$event.stopPropagation()"
              />
            }
            @if (searchControl.value) {
              <button
                matIconSuffix
                mat-icon-button
                type="button"
                data-cy="search-clear-button"
                (click)="searchControl.reset()"
              >
                <mat-icon matIconSuffix>clear</mat-icon>
              </button>
            }
          </div>
        </div>
        @let hasPerformanceOptimization = data.length > 99;
        <div
          class="selection-panel"
          #scrollPanel
          [class.overflow-auto]="!hasPerformanceOptimization"
        >
          @if (hasPerformanceOptimization) {
            <cdk-virtual-scroll-viewport itemSize="10" class="scroll-viewport">
              <ng-container
                *cdkVirtualFor="let option of data; trackBy: trackByValue"
              >
                <mat-option
                  [value]="option.value"
                  [class.hidden-option]="
                    !!searchControl.value &&
                    !option.viewValue
                      .toLocaleLowerCase()
                      .includes(searchControl.value.toLocaleLowerCase())
                  "
                  [attr.data-cy]="option.viewValue + '-option'"
                >
                  <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    [fxLayoutGap]="0.25 | dsSpacing"
                  >
                    @if (option.icon) {
                      @let icon = option.icon;
                      <span
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        class="icon-placeholder"
                      >
                        @if (
                          icon.indexOf('assets') !== -1 ||
                          icon.indexOf('http') !== -1
                        ) {
                          <img class="icon" height="20" [src]="icon" />
                        } @else if (icon | isPdIcon) {
                          <mat-icon
                            class="icon"
                            fontSet="pd"
                            [fontIcon]="icon"
                          />
                        } @else if (icon | isEquipmentIcon) {
                          <ds-equipment-icon [type]="icon" />
                        } @else {
                          <mat-icon class="icon">{{ icon }}</mat-icon>
                        }
                      </span>
                    }
                    <span
                      class="nowrap-text"
                      [ngClass]="{
                        'error-text': option.textIndicators === 'error',
                        'warning-text': option.textIndicators === 'warning',
                        'info-text': option.textIndicators === 'info',
                        'success-text': option.textIndicators === 'success',
                      }"
                      [textContent]="option.viewValue"
                    ></span>
                  </div>
                </mat-option>
              </ng-container>
            </cdk-virtual-scroll-viewport>
          } @else {
            @for (option of data; track $index) {
              <mat-option
                [value]="option.value"
                [class.hidden-option]="
                  !!searchControl.value &&
                  !option.viewValue
                    .toLocaleLowerCase()
                    .includes(searchControl.value.toLocaleLowerCase())
                "
                [attr.data-cy]="option.viewValue + '-option'"
              >
                <div
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  [fxLayoutGap]="0.25 | dsSpacing"
                >
                  @if (option.icon) {
                    @let icon = option.icon;
                    <span
                      fxLayout="row"
                      fxLayoutAlign="center center"
                      class="icon-placeholder"
                    >
                      @if (
                        icon.indexOf('assets') !== -1 ||
                        icon.indexOf('http') !== -1
                      ) {
                        <img class="icon" height="20" [src]="icon" />
                      } @else if (icon | isPdIcon) {
                        <mat-icon class="icon" fontSet="pd" [fontIcon]="icon" />
                      } @else if (icon | isEquipmentIcon) {
                        <ds-equipment-icon [type]="icon" />
                      } @else {
                        <mat-icon class="icon">{{ icon }}</mat-icon>
                      }
                    </span>
                  }
                  <span
                    class="nowrap-text"
                    [ngClass]="{
                      'error-text': option.textIndicators === 'error',
                      'warning-text': option.textIndicators === 'warning',
                      'info-text': option.textIndicators === 'info',
                      'success-text': option.textIndicators === 'success',
                    }"
                    [textContent]="option.viewValue"
                  ></span>
                </div>
              </mat-option>
            }
          }

          @if (isDataLoading) {
            <mat-spinner
              class="data-loading-spinner"
              matTextSuffix
              color="accent"
              [diameter]="20"
            />
          }

          @if (!data?.length) {
            <mat-option disabled [class.hidden-option]="!showNotAvailable">{{
              'general.not_available' | translate
            }}</mat-option>
          }

          <ng-content />
        </div>
        @if (isPaginated) {
          @for (value of selectedButNotLoadedValues; track $index) {
            <mat-option [value]="value" class="hidden-option" />
          }
        }
      </mat-select>

      @if (isLoading) {
        <mat-spinner color="accent" matTextSuffix diameter="20" />
      }

      @if ((control.value | showClearButton) && !control.disabled) {
        <button
          mat-icon-button
          matIconSuffix
          (click)="clear($event); checkBox ? (checkBox.checked = false) : ''"
          [matTooltip]="'general.clear' | translate"
          class="clear-btn"
          type="button"
          [attr.data-cy]="label + '-clear-button'"
        >
          <mat-icon>close</mat-icon>
        </button>
      }

      <mat-error> <ng-content select="“[‘mat-error’]“" /> </mat-error>
      <mat-hint>
        <ng-content select="“[‘mat-hint’]“" />
      </mat-hint>
    </mat-form-field>
  } @else {
    <mat-form-field
      [ngClass]="{ 'no-arrow ': isLoading }"
      class="full-width"
      [hideRequiredMarker]="hideRequiredMarker"
    >
      <mat-label>
        {{ label }}
        @if (
          ((!!data && data.length > 2) || isPaginated) && !isMultiChoiceDisabled
        ) {
          <span>
            ({{ control.value?.length || 0 }}/{{ totalCount || data?.length }})
          </span>
        }
      </mat-label>

      <mat-select
        [panelClass]="'ds-filter-panel'"
        [class.mat-mdc-select-disabled]="isLoading"
        [formControl]="control"
        [errorStateMatcher]="errorStateMatcher"
        [attr.data-cy]="label + '-select'"
      >
        @for (option of data; track $index) {
          <mat-option
            [value]="option.value"
            [class.hidden-option]="
              !!searchControl.value &&
              !option.viewValue
                .toLocaleLowerCase()
                .includes(searchControl.value.toLocaleLowerCase())
            "
            [attr.data-cy]="option.viewValue + '-option'"
          >
            @if (option.icon) {
              @let icon = option.icon;
              <span
                fxLayout="row"
                fxLayoutAlign="center center"
                class="icon-placeholder"
              >
                @if (
                  icon.indexOf('assets') !== -1 || icon.indexOf('http') !== -1
                ) {
                  <img class="icon" height="20" [src]="icon" />
                } @else if (icon | isPdIcon) {
                  <mat-icon class="icon" fontSet="pd" [fontIcon]="icon" />
                } @else if (icon | isEquipmentIcon) {
                  <ds-equipment-icon [type]="icon" />
                } @else {
                  <mat-icon class="icon">{{ icon }}</mat-icon>
                }
              </span>
            }
            <span
              [ngClass]="{
                'error-text': option.textIndicators === 'error',
                'warning-text': option.textIndicators === 'warning',
                'info-text': option.textIndicators === 'info',
                'success-text': option.textIndicators === 'success',
              }"
              class="nowrap-text"
              [textContent]="option.viewValue"
            ></span>
          </mat-option>
        }
        @if (!data?.length) {
          <mat-option disabled>{{
            'general.not_available' | translate
          }}</mat-option>
        }
      </mat-select>

      @if (isLoading) {
        <mat-spinner color="accent" matTextSuffix diameter="20" />
      }
      @if ((control.value | showClearButton) && !control.disabled) {
        <button
          mat-icon-button
          matIconSuffix
          (click)="clear($event); checkBox ? (checkBox.checked = false) : ''"
          [matTooltip]="'general.clear' | translate"
          class="clear-btn"
          type="button"
          [attr.data-cy]="label + '-clear-button'"
        >
          <mat-icon>close</mat-icon>
        </button>
      }
      <mat-error>
        <ng-content select="“[‘mat-error’]“" />
      </mat-error>
      <mat-hint>
        <ng-content select="“[‘mat-hint’]“" />
      </mat-hint>
    </mat-form-field>
  }
}
