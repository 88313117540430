import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSortModule } from '@angular/material/sort';
import { PdLetDirectiveModule } from '@cdk/pd-let';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { AlternativeTableRendererItemComponent } from './alternative-table-renderer/alternative-table-renderer-item/alternative-table-renderer-item.component';
import { AlternativeTableRendererComponent } from './alternative-table-renderer/alternative-table-renderer.component';
import {
  DsTableActions,
  DsTableComponent,
  DsTableCustomFilter,
  DsTableFooter,
} from './table.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    DsSpacingModule,
    MatCardModule,
    MatExpansionModule,
    MatSortModule,
    PdLetDirectiveModule,
  ],
  declarations: [
    DsTableComponent,
    DsTableActions,
    DsTableFooter,
    AlternativeTableRendererComponent,
    AlternativeTableRendererItemComponent,
    DsTableCustomFilter,
  ],
  exports: [
    DsTableComponent,
    DsTableActions,
    DsTableFooter,
    DsTableCustomFilter,
  ],
})
export class DsTableModule {}
