import { Component, Input } from '@angular/core';
import { ProductTypes } from '@config';

interface IconModel {
  url: string;
  width: number;
  height: number;
}

class Icon implements IconModel {
  url = '';
  width = 0;
  height = 32;

  constructor(filename: string, width?: number) {
    this.url = '/assets/img/product-icons/' + filename;
    this.width = width || 50;
  }
}

@Component({
  selector: 'ds-equipment-icon',
  template:
    '<img [style.width.px]="icon.width" [style.max-width.px]="maxHeight" [src]="icon.url" alt="equipment icon"> ',
  styles: [
    ':host{justify-content: center;align-items: center;margin-right: 8px;place-content: start;align-items: center;}',
  ],
  standalone: false,
})
export class DsEquipmentIconComponent {
  @Input() type: string;
  @Input()
  hasTelematic = false;
  @Input()
  width?: number;
  @Input()
  maxHeight = 32;

  // eslint-disable-next-line sonarjs/cognitive-complexity
  get icon(): Icon {
    // eslint-disable-next-line sonarjs/max-switch-cases
    switch (this.type) {
      case 'crane':
      case ProductTypes.LoaderCranes:
        return new Icon(
          this.hasTelematic
            ? '001_Loader_Cranes_Connected.png'
            : '001_Loader_Cranes.png',
          this.width ? this.width : undefined,
        );
      case 'railway':
      case ProductTypes.Railway:
        return new Icon('002_Railway.png', this.width ? this.width : undefined);
      case 'railway_platforms':
      case 'platforms':
      case ProductTypes.AccessPlatforms:
        return new Icon(
          this.hasTelematic
            ? '003_Access_Platforms_Connected.png'
            : '003_Access_Platforms.png',
          this.width ? this.width : undefined,
        );
      case ProductTypes.TruckMountedForklifts:
        return new Icon(
          this.hasTelematic
            ? '004_Truck_Mounted_Forklifts_Connected.png'
            : '004_Truck_Mounted_Forklifts.png',
          this.width ? this.width : undefined,
        );

      case 'taillift':
      case 'TLI':
      case ProductTypes.TailLifts:
        return new Icon(
          this.hasTelematic
            ? '006_Tail Lifts_Connected.png'
            : '006_Tail Lifts.png',
          this.width ? this.width : undefined,
        );
      case 'hookloader':
      case ProductTypes.ContainerHandlingSystems:
        return new Icon(
          this.hasTelematic
            ? '007_Container_Handling_Systems_Connected.png'
            : '007_Container_Handling_Systems.png',
          this.width ? this.width : undefined,
        );
      case 'epsilon':
      case ProductTypes.EpsilonTimberAndRecycling:
        return new Icon(
          this.hasTelematic
            ? '010_Epsilon_Timber_and_Recycling_Connected.png'
            : '010_Epsilon_Timber_and_Recycling.png',
          this.width ? this.width : undefined,
        );
      case 'skiploader':
      case ProductTypes.Skiploader:
        return new Icon(
          this.hasTelematic
            ? '011_Skiploader_Connected.png'
            : '011_Skiploader.png',
          this.width ? this.width : undefined,
        );
      case ProductTypes.MarineCranes:
        return new Icon(
          this.hasTelematic
            ? '013_Marine_Cranes_Connected.png'
            : '013_Marine cranes.png',
          this.width ? this.width : undefined,
        );
      case ProductTypes.WindCranes:
        return new Icon(
          '026_Wind cranes.png',
          this.width ? this.width : undefined,
        );
      case ProductTypes.EpsilonOffRoad:
        return new Icon(
          '030_Epsilon_Offroad.png',
          this.width ? this.width : undefined,
        );
      case ProductTypes.ServiceCranes:
        return new Icon(
          '031_Service_Cranes_NA.png',
          this.width ? this.width : undefined,
        );
      case ProductTypes.TruckBodies:
        return new Icon(
          '032_Truck_bodies_NA_update_2018.png',
          this.width ? this.width : undefined,
        );
      case ProductTypes.PALPROMechanicTrucks:
        return new Icon(
          '034_PAL_PRO_Mechanic_Trucks_NA.png',
          this.width ? this.width : undefined,
        );
      case ProductTypes.LargeAerialPlatforms:
        return new Icon(
          '035_Large_Aerial_Platforms_NA.png',
          this.width ? this.width : undefined,
        );
      case ProductTypes.CableHoists:
        return new Icon(
          '036_Cable_Hoists_NA.png',
          this.width ? this.width : undefined,
        );
      case ProductTypes.Hoists:
        return new Icon(
          '037_Hoists_NA.png',
          this.width ? this.width : undefined,
        );
      case ProductTypes.PickUpLifts:
        return new Icon(
          '039_Pick_up_Lifts_NA.png',
          this.width ? this.width : undefined,
        );
      case ProductTypes.AccessPlatformPPISmart:
        return new Icon(
          this.hasTelematic
            ? '040_Access_Platform_PPI_Smart_Connected.png'
            : '040_Access_Platform_PPI_Smart.png',
          this.width ? this.width : undefined,
        );
      case 'crawlercrane':
      case ProductTypes.CrawlerCrane:
        return new Icon(
          this.hasTelematic
            ? '043_Crawler_Crane_Connected.png'
            : '043_Crawler_Crane.png',
          this.width ? this.width : undefined,
        );
      case ProductTypes.BusLift:
        return new Icon(
          '044_Passenger_Lifts_Update.png',
          this.width ? this.width : undefined,
        );
      case ProductTypes.BridgeInspectionUnits:
      case '042':
        return new Icon(
          '045_Bridge_Inspection_Units.png',
          this.width ? this.width : undefined,
        );
      case ProductTypes.OffshoreCranes:
        return new Icon(
          '046_Offshore cranes.png',
          this.width ? this.width : undefined,
        );
      case ProductTypes.LifeSavingEquipment:
        return new Icon(
          '047_Lifesaving equipment.png',
          this.width ? this.width : undefined,
        );
      case ProductTypes.LifeAndRescueBoats:
        return new Icon(
          '048_Life and rescue boats.png',
          this.width ? this.width : undefined,
        );
      case ProductTypes.Winches:
        return new Icon('049_Winches.png', this.width ? this.width : undefined);
      case ProductTypes.ForestryAndScrapCranes:
        return new Icon(
          '050_Forestry_and_Scrap_Cranes_NA.png',
          this.width ? this.width : undefined,
        );
      case ProductTypes.Inman:
        return new Icon('052_Inman.png', this.width ? this.width : undefined);
      case ProductTypes.ETISolutions:
      case '056':
      case '057':
        return new Icon(
          '053_ETI_Solutions_NA.png',
          this.width ? this.width : undefined,
        );
      case ProductTypes.Davits:
        return new Icon('054_Davits.png', this.width ? this.width : undefined);
      case '101':
        return new Icon(
          '101_equipment.svg',
          this.width ? this.width : undefined,
        );
      case '102':
        return new Icon(
          '102_turnkey_solution.svg',
          this.width ? this.width : undefined,
        );
      default:
        return new Icon(
          '055_uncategorized.jpg',
          this.width ? this.width : undefined,
        );
    }
  }
}
